import './App.css';
import { useCallback, useEffect, useState } from 'react';
import "react-toggle/style.css";


const baseURL = 'https://api.babynameelo.stafman.com/';
function App() {

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [eloDiff1, setEloDiff1] = useState(0);
  const [eloDiff2, setEloDiff2] = useState(0);

  const getNames = useCallback(() => {
    fetch(baseURL + '2names', {
      'method': "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 2) {
          setName1(data[0]);
          setName2(data[1]);
        }
      });
  }, []);
  useEffect(() => { getNames(); }, [getNames]);
  const name1votelink = baseURL + "vote?name1=" + name1 + "&name2=" + name2;
  const name2votelink = baseURL + "vote?name1=" + name2 + "&name2=" + name1;

  const cleanup = useCallback(() => {
    setEloDiff1(0);
    setEloDiff2(0);
    getNames();
  }, [setEloDiff1, setEloDiff2, getNames]);
  // accepts a boolean, name1 = true if name1 wins
  const vote = useCallback((name1) => {
    fetch(name1 ? name1votelink : name2votelink)
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 2) {
          setEloDiff1(name1 ? data[0] : data[1]);
          setEloDiff2(name1 ? data[1] : data[0]);
        }
      });
    setTimeout(cleanup, 1000);
  }, [name1votelink, name2votelink, setEloDiff1, setEloDiff2, cleanup]);

  const veto = useCallback((name) => {
    fetch(baseURL + 'veto?name=' + name);
    getNames();
  }, [getNames]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Baby Name ELO
        </h1>
        <div>
          <Name name={name1} vote={vote} veto={veto} isName1={true} eloDiff={eloDiff1} />
          <Name name={name2} vote={vote} veto={veto} isName1={false} eloDiff={eloDiff2} />
        </div>
      </header>

      <TopList name={name1} />
    </div>
  );
}

function Name({ name, vote, veto, isName1, eloDiff }) {
  const style = {
    padding: "10px",
    cursor: "pointer",
    borderStyle: 'dashed',
    borderWidth: '1px',
    margin: "2px",
    userSelect: "none",
  };
  return <div style={{display: 'flex'}}><div
    onClick={x => vote(isName1)}
    style={style}
  >
    {eloDiff !== 0 ? <div className="eloDiff" style={{ color: (eloDiff > 0 ? "green" : "red") }}>{eloDiff}</div> : null}
    {name}
  </div><div style={{...style, color: 'red'}} onClick={x => veto(name)}>X</div></div>;
}

function TopList({ name }) {
  const [list, setList] = useState([]);
  useEffect(() => {
    fetch(baseURL + 'topranked?n=50&k=0')
      .then((response) => response.json())
      .then((data) => {
        setList(data);
      });
  }, [name]);
  return <div className="toplist">
    <div>{list.length}</div>
    {list.map(item =>
      <div key={item[0]}><span>{item[0]}</span> <span>{item[1]}</span></div>
    )}
  </div>
}

export default App;
